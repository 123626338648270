<template>
  <div id="NoData" class="aqt_textC" :class="color" :style="{fontSize:imgsize+'px'}">
    <i  class="iconNull-data iconNodata"></i>
    <div v-if="MN" class="titlefont aqt_lang_mn text aqt_ce_ve"  :class="fontsize" style="paddingTop:10px">{{txt}}</div>
    <div v-else :class="fontsize" style="paddingTop:10px">{{txt}}</div>
    <div
      v-if="buttontxt"
      class="nodatabutton font_size_one"
      :class="[buttoncolorbj,buttoncolor]"
      @click="nodatabutton"
    >{{buttontxt}}</div>

  </div>
</template>

<script>
export default {
  props: {
    MN: {
      type: Boolean,
      default: false
    },
    imgsize: {
      type: String,
      default: '100'
    },
    fontsize: {
      type: String,
      default: 'font_size_one'
    },
    color: {
      type: String,
      default: 'font_color_seven'
    },
    txt: {
      type: String,
      default: '暂无数据'
    },
    buttontxt: {
      type: String,
      default: ''
    },
    buttoncolorbj: {
      type: String,
      default: 'themebgone'
    },
    buttoncolor: {
      type: String,
      default: 'font_color_two'
    },
    topage: {
      type: String,
      default: ''
    }
  },
  methods: {
    /************************************************************
     *
     * button方法
     *
     ************************************************************/
    nodatabutton() {
      this.$router.replace({
        path: this.topage
      })
    }
  }
}
</script>
<style lang="less">
  .font_color_seven {
  color: #96969b;
}
  #NoData {
  height: 100%;
  width: 100%;
  margin: 100px 0;
  text-align: center;
  .text{
    width: 100%;
    height: 50px;
    font-size: 30px;
  }
  .iconNodata {
    font-family: 'iconfont' !important;
    font-style: normal; // font-size   : 150px/@Number;
    font-weight: 600;
  }
  .font_size_one{
    font-size: 20px;
  }

  .nodatabutton {
    display: inline;
    padding: 20px 80px ;
    border-radius: 10px ;
  }
  img {
    height: 180px;
  }
}
</style>
